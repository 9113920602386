import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import BlogList from "../components/blog/blog-list"


const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    query blogIndexQuery {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        filter: {
          fileAbsolutePath: {
              regex: "/\/blog\//"
          }
        }
      ) {
        nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              description
              author
              quote
              quoteAuthor
              category
              tags
            } 
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Blog" />
      <BlogList title="Blog Posts" posts={data.allMarkdownRemark.nodes}></BlogList>
    </Layout>
  )
}

export default BlogIndex
